import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getContentBlock } from 'selectors/event';
import { getImageUrl } from 'helper/helper';

const Footer = (props) => {
	const { contentBlocks } = props;
	return (
		<footer>
			{contentBlocks.footer_text && (
				<div className="footer">
					<div className="container">
						<div
							dangerouslySetInnerHTML={{
								__html: contentBlocks.footer_text,
							}}
						></div>
					</div>
				</div>
			)}
			<div className="footer">
				<div className="container">
					<div className="mobile-footer">
						<img src={getImageUrl(`/assets/images/AMM Mobile Footer.jpg`)} />
					</div>
					<div className="desktop-footer">
						<img src={getImageUrl(`/assets/images/AMM Desktop Footer.jpg`)} />
					</div>
				</div>
			</div>
		</footer>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(Footer));
