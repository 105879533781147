export const SpeakersData = [
	{
		name: 'ADAM BROWN',
		photo: 'Adambrown_headshot.PNG',
		description:
			'Adam is the Advantedge Executive and heads the National White Label Business for Nab Group.  He is accountable for delivering on the Advantedge Strategy of providing a simple home loan proposition with competitive pricing supported by great service.',
	},
	{
		name: 'DANIEL BARROW',
		photo: '',
		description: 'Dan is the Head of Broker Sales for New South Wales.',
	},
	{
		name: 'GEOFF BUNGEY',
		photo: 'Geoff Bungey.png',
		description: 'Geoff is the Head of Product at Advantedge.',
	},
	{
		name: 'JESS VAIANO',
		photo: 'jessvaiano_headshot.PNG',
		description:
			'Jess Vaiano is the Head of Brokers Sales for our Southern region and has been with Advantedge for over 15 years.',
	},
	{
		name: 'MARK BROWNING',
		photo: 'Mark Browning_headshot.jpg',
		description: 'Mark is NAB Group’s Head of Valuations & Property Advisory',
	},
	{
		name: 'SARAH-JAYNE HAWKES',
		photo: 'sarahjaynehawkes_headshot.jpg',
		description:
			'As the  Advantedge Fulfilment & Servicing Production Manager Sarah-Jayne works to improve the operations, risk and change elements for colleagues and customers.',
	},
	{
		name: 'TIM LAWLESS',
		photo: 'timlawless_headshot.PNG',
		description:
			'Tim is CoreLogic’s research director for Asia Pacific. Tim’s expertise across property markets has positioned him as one of the country’s most experienced and popular property market analysts and commentators.',
	},
	{
		name: 'VANESSA FEKONJA',
		photo: 'vanessafekonja_headshot.PNG.jpg',
		description:
			'Vanessa is the Senior Consultant for Strategic Marketing at NAB and Advantedge. Vanessa has extensive experience in brand and product marketing, with a particular focus on communications and the development of customer value propositions.',
	},
	{
		name: 'Christian Malouf',
		photo: '128441989-fb8bd704-5c4e-4ab9-967b-6d294bc992b5.jpg',
		description:
			'Christian Malouf (Manager Business Development) is a career banker of 15+ years and has been working with brokers in the third party space for over 10 years. Christian is passionate about offering brokers a simple yet brilliant White Label proposition, with absolute clarity on what types of customers that the Advantedge offering is perfect for. He believes in establishing long term broker/customer relationships built on quality product, support and customer service.',
	},
];

export const AgendaData = [
	{
		name: 'NAB Federal Budget 2021 Insights for Metro Australia Customers',
		sessions: [
			{
				time: '9:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '9:05am',
				session: ['Federal Budget 2021 economic analysis with Alan Oster'],
			},
			{
				time: '9:25am',
				session: [
					'Panel discussion with Michael Saadie, Sally Auld, Paul Abbey and Alan Oster, hosted Nassim Khadem',
				],
			},
			{
				time: '9:45am',
				session: ['Live Q&A with audience'],
			},
			{
				time: '10:00am',
				session: ['Event to conclude'],
			},
		],
	},
	{
		name: 'NAB Federal Budget 2021 Insights for Regional Australia and Agribusiness Customers',
		sessions: [
			{
				time: '11:00am',
				session: ['Nassim Khadem (MC) Welcome and Session Introduction'],
			},
			{
				time: '11:05am',
				session: ['Federal Budget 2021 economic analysis with Phin Ziebell'],
			},
			{
				time: '11:25am',
				session: [
					'Panel discussion with Julie Rynski, Sally Auld, Paul Abbey and Phin Ziebell, hosted by Nassim Khadem',
				],
			},
			{
				time: '11:45am',
				session: ['Live Q&A to commence'],
			},
			{
				time: '12:00pm',
				session: ['Event to Conclude'],
			},
		],
	},
];

export const FirstSixSession = [
	{
		timing: '11:05am – 11:10am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 1:</span>  Welcome and day 1 introduction',
		sessionInformation:
			'Day one introduction and overview – what to expect and how to get the most out of the webinar series.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li></ul>',
	},
	{
		timing: '11:10am – 11:15am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 2:</span>  Adapting and thriving in changing times',
		sessionInformation:
			'Listen to the experiences of a range of brokers and BDMs during COVID-19 and how Advantedge has supported them in the process. Prepare to be inspired!  ',
	},
	{
		timing: '11:15am – 11:25pm [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 3:</span>  Built for simplicity, built for better',
		sessionInformation:
			'Take an exclusive first look at the new Advantedge customer and broker value propositions, all based on extensive quant and qual research.<br/><br/><div class="theme-title">Host:</div><br/><ul><li>Vanessa Fekonja, Senior Marketing Consultant</li></ul>',
	},
	{
		timing: '11:25am – 11:45am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 4:</span>  Deep dive – learnings and new initiatives',
		sessionInformation:
			'Join the Advantedge Leadership Team as they discuss the learnings of the past year and take you through a series of key initiatives focused on simplification, fulfilment and servicing.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li><li>Geoff Bungey, Head of Customer Epidsode, Advantedge</li><li>Peter Fourtzis, Distribution Support Manager, Advantedge</li><li>Sarah- Jane Hawkes, Production Manager, Advantedge Servicing and Fulfilment</li></ul>',
	},
	{
		timing: '11:45pm – 11:50am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 5:</span>  You Can’t Ask That...or can you?',
		sessionInformation:
			'Watch the Advantedge team get put through their paces in a <i>You Can’t Ask That</i>-style Q&A. This session is set to offer a mix of thought-provoking and entertaining insights.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Christian Malouf, Manager, Business Development, Advantedge</li><li>Daniel Barrow, Head of Broker Sales – Northern Region, Advantedge</li><li>Brad Crawford, Business Development Manager, Advantedge</li></ul>',
	},
	{
		timing: '11:50am – 11:55am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 6:</span>  Day 1 wrap and close',
		sessionInformation: 'Day one wrap and close – quick overview of day two.',
	},
];

export const SecondFourSessions = [
	{
		timing: '11:05am – 11:10am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 7:</span>  Welcome and day 2 introduction',
		sessionInformation:
			'Day two introduction and overview. Brief re-cap and feedback from day one.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Adam Brown, Executive - Advantedge</li></ul>',
	},
	{
		timing: '11:10am – 11:25am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 8:</span>  CoreLogic: market trends and outlook',
		sessionInformation:
			'With coverage of 98% of the Australian property market and a database of more than 4 billion decision points, CoreLogic offers the most reliable and powerful insights. Tune in to hear the latest trends and broker-first opportunities.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Mark Browning, Head of Property Services Group, NAB</li><li>Tim Lawless, Head of Research, CoreLogic Asia Pacific</li></ul>',
	},
	{
		timing: '11:25am – 11:50am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 9:</span>  Live Q&A with the Advantedge expert panel',
		sessionInformation:
			'Join our live expert panel to get all the answers to your burning questions of the day, and everything else in between! We encourage you to send your questions ahead of the webinar, or feel free to submit them on the day.<br/><br/><div class="theme-title">Hosts:</div><br/><ul><li>Jess Vaiano, Head of Broker Sales – Southern Region, Advantedge</li><li>Sarah-Jane Hawkes, Production Manager, Advantedge Servicing and Fulfilment</li><li>Steven Marinucci, Manager, Business Development Manager, Advantedge</li></ul>',
	},
	{
		timing: '11:50am – 11:55am [AEST]',
		sessionName:
			'<span class="theme-title">SESSION 10:</span>  Day 2 wrap, close and thank you',
		sessionInformation:
			'Event wrap and close, thank you and our commitment to you.',
	},
];
