import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import SessionInformation from './Components/SessionInformation';
import Polls from './Components/Polls';
import LiveQA from './Components/LiveQA';
import Agenda from './Components/Agenda';
import { getQuestionActive } from 'selectors/session';

const SideDrawer = (props) => {
	const {
		displayQuestion,
		questionLabel,
		sessionLabel,
		agendaLabel,
		pollLabel,
		allQuestion,
		myQuestion,
		askQuestion,
		agendaTab,
		isQuestionActive,
		question_description_caption,
		pollStatus,
	} = props;

	const initial = {
		questionActive: false,
	};
	const [state, setState] = useState(initial);
	let { slug, id } = useParams();
	useEffect(() => {
		let questionActive = isQuestionActive(id);

		setState({ ...state, questionActive: questionActive });
	}, [slug, id]);
	return (
		<div className="sidebar">
			{agendaTab && <Agenda label={agendaLabel} />}
			<SessionInformation label={sessionLabel} />
			{isQuestionActive(id) && displayQuestion && (
				<LiveQA
					label={questionLabel}
					displayAll={allQuestion}
					displayMy={myQuestion}
					displayAsk={askQuestion}
					question_description_caption={question_description_caption}
				/>
			)}
			{pollStatus && <Polls label={pollLabel} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isQuestionActive: (id) => getQuestionActive(state, id),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(SideDrawer);
