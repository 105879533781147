import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { allSessions, forgotLink } from 'actions/event';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { ValidateEmail } from 'util/Validator';
import { getContentBlock } from 'selectors/event';
import { isLoggedIn } from 'selectors/auth';
import CMSRegistrationForm from 'views/Registration/CMSRegistrationForm';
import { Link } from 'react-router-dom';

const Home = (props) => {
	const [state, setState] = useState({
		events: [],
		error: '',
		displayForm: false,
		submitting: false,
		email: '',
		success: '',
	});
	const [error, setError] = useState({ email: '' });
	const [currentData, setCurrentData] = useState({
		register: '',
		slug: '',
	});
	const { allSessions, forgotLink, contentBlocks, isLoggedIn } = props;

	function callbackSuccess(data) {
		let { slug, video_sessions, registration_slug } = data;
		let sortedData = video_sessions.sort(sortEvents);

		setCurrentData({
			...currentData,
			slug: slug,
			register: registration_slug,
		});
		setState({ ...state, events: sortedData });
	}

	function callbackError(error) {
		setState({ ...state, error: error.message });
	}
	useEffect(() => {
		allSessions(callbackSuccess, callbackError);
	}, []);

	const sortEvents = (a, b) => {
		const bandA = a.id;
		const bandB = b.id;

		let comparison = 0;
		if (bandA < bandB) {
			comparison = -1;
		} else if (bandA > bandB) {
			comparison = 1;
		}
		return comparison;
	};

	const forgotSuccess = () => {
		setState({
			...state,
			success: 'Session link has been sent to your email.',
			submitting: false,
			email: '',
		});
		setError({ ...error, email: '' });
	};

	const forgotError = (err) => {
		setState({ ...state, submitting: false });
		setError({
			...error,
			email: err.message.email ? err.message.email : err.message,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setState({ ...state, submitting: true });
		if (!state.email) {
			setError({ ...error, email: 'Email address is required' });
			setState({ ...state, submitting: false });
			return;
		}

		if (!ValidateEmail(state.email)) {
			setState({ ...state, submitting: false });
			setError({ ...error, email: 'Please enter valid email address.' });
			return;
		}

		forgotLink(state.email, forgotSuccess, forgotError);
	};

	const registrationButton = () => {
		if (
			!isLoggedIn(currentData.slug) &&
			currentData.register &&
			currentData.slug
		) {
			return `<a class="btn btn-default btn-md contrast-color" href="/${currentData.slug}/register/${currentData.register}">
					Register
				</a>`;
		} else {
			return '';
		}
	};

	return (
		<div className="welcome-info">
			<Row>
				<Col md="12">
					<div
						dangerouslySetInnerHTML={{
							__html: contentBlocks.welcome_text.replace(
								'{registration_button}',
								registrationButton()
							),
						}}
					></div>
				</Col>
			</Row>
			{state.displayForm && (
				<Card className="my-2 slow-motion">
					<Card.Body>
						<Form onSubmit={(e) => handleSubmit(e)}>
							<Form.Group>
								<Form.Label>
									Please enter the email address you used to register
								</Form.Label>
								<Form.Control
									type="email"
									isInvalid={error.email}
									value={state.email}
									placeholder="Email address"
									onChange={(e) =>
										setState({ ...state, email: e.target.value })
									}
									required={true}
								/>
								<Form.Control.Feedback type="invalid">
									{error.email}
								</Form.Control.Feedback>
								{state.success && (
									<p className="small success">{state.success}</p>
								)}
							</Form.Group>
							<Button type="submit" size="sm" variant="dark mx-auto">
								{state.submitting ? (
									<>
										<Spinner animation="border" size="sm" /> Submitting
									</>
								) : (
									'Resend Link'
								)}
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}
			{/* <Row>
				<Col md={12}>
					<Suspense fallback={null}>
						<AllEvents videoSessions={state.events} />
					</Suspense>
					{state.error && (
						<h4 className="invalid-feedback error">{state.error}</h4>
					)}
				</Col>
			</Row> */}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	allSessions,
	forgotLink,
};

export default connect(mapStateToProps, dispatchProps)(Home);
