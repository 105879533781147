import * as allTypes from 'actions/types';

const defaultSystemState = {
	sidebar: true,
	displayVideo: true,
	displaySidebarToggle: true,
	displayVideoToggle: true,
	sidebarState: [],
	googleId: '',
	header_bg: '',
	footer_bg: '',
	body_dynamic: '',
	displayVideoPlayer: true,
};

export const system = (state = defaultSystemState, action) => {
	switch (action.type) {
		case allTypes.UPDATE_SYSTEM: {
			return {
				...state,
				sidebar: !state.sidebar,
			};
		}
		case allTypes.UPDATE_TOGGLE_VIDEO: {
			return {
				...state,
				displayVideo: !state.displayVideo,
			};
		}
		case allTypes.UPDATE_SYSTEM_TOGGLE: {
			const {
				sidebar_toggle,
				video_toggle,
				header_bg,
				footer_bg,
				body_dynamic,
			} = action;
			return {
				...state,
				displaySidebarToggle: sidebar_toggle,
				displayVideoToggle: video_toggle,
				header_bg: header_bg,
				footer_bg: footer_bg,
				body_dynamic: body_dynamic,
			};
		}
		case allTypes.UPDATE_GOOGLE_ID: {
			const { tag } = action;
			return {
				...state,
				googleId: tag,
			};
		}
		case allTypes.UP_GOOGLE_ID: {
			const { googleId } = action;
			return {
				...state,
				googleId: googleId,
			};
		}
		case allTypes.SET_VIDEO_PLAYER: {
			const { display_type } = action;
			return {
				...state,
				displayVideoPlayer: display_type,
			};
		}
		default:
			return state;
	}
};
