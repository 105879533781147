import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { eventRegistration, submitEventRegistration } from 'actions/event';
import Form from 'react-bootstrap/Form';
import Fields from './Fields';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { isLoggedIn } from 'selectors/auth';
import { MULTIPLE_SESSION, EMAIL, CONFIRM_EMAIL } from 'constants/Fields';
import { validateFormWithoutSession } from 'util/Validator';
import { ErrorToast, SuccessToast } from 'components/ToastMessage/Toast';

const CMSRegistrationForm = (props) => {
	const initial = {
		loading: true,
		formWithoutSession: [],
		error: '',
		submitForm: {},
		reload: false,
		emailStack: [],
		submitting: false,
	};
	const [state, setState] = useState(initial);
	let { slug, event_slug } = props;

	const handleChange = (e) => {
		state.submitForm[`${e.target.name}`] = e.target.value;
		setState({
			...state,
			submitForm: { ...state.submitForm },
		});
	};

	const callbackSuccess = (data) => {
		setState({ ...state, ...data, loading: false, error: '' });
	};
	const callbackError = () => {
		setState({ ...state, error: 'Event does not exists.', loading: false });
	};

	useEffect(() => {
		const { eventRegistration } = props;
		if (slug) eventRegistration(slug, callbackSuccess, callbackError);
	}, [slug, state.reload === true]);

	useEffect(() => {
		if (state.form_fields) {
			var formWithoutSession = state.form_fields.filter(function (data) {
				return data.field_type != MULTIPLE_SESSION;
			});

			var emailStack = formWithoutSession.filter(function (data) {
				return data.field_type === EMAIL || data.field_type === CONFIRM_EMAIL;
			});

			setState({
				...state,
				formWithoutSession: formWithoutSession,
				emailStack: emailStack,
			});
		}
	}, [state.form_fields]);

	const submitSuccess = (responseData) => {
		const { login, message } = responseData;
		const { slug } = responseData.data;
		if (login) {
			props.history.push(`/${slug}/info`);
		} else {
			setState(initial);
			setState({ ...state, reload: true });
		}
		SuccessToast(message, 3000);
		setState({ ...state, submitting: false });
	};
	const submitError = (e) => {
		ErrorToast(e.message[0].error);
		setState({ ...state, submitting: false });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState({ ...state, submitting: true });
		if (state.emailStack.length > 1) {
			if (
				state.submitForm[state.emailStack[0].id] !==
				state.submitForm[state.emailStack[1].id]
			) {
				ErrorToast('Two email does not match');
				setState({ ...state, submitting: false });
				return;
			}
		}
		let error = validateFormWithoutSession(
			state.formWithoutSession,
			state.submitForm
		);
		if (error.length <= 0) {
			const { submitEventRegistration } = props;
			submitEventRegistration(
				slug,
				state.submitForm,
				[],
				submitSuccess,
				submitError
			);
		}
	};

	return (
		<Card>
			<Card.Body>
				<div className="event-details CMS">
					{!state.loading && state.formWithoutSession && (
						<div className="content">
							<div className="NAB CMS-registration">{state.title}</div>
							<Form onSubmit={(e) => handleSubmit(e)}>
								<div className="form-flex mt-2">
									{state.formWithoutSession.map((data, index) => (
										<div key={index}>
											<Fields
												data={data}
												submitForm={state.submitForm}
												change={handleChange}
												registrationsActive={state.registrations_active}
											/>
										</div>
									))}
								</div>

								{state.formWithoutSession && (
									<div>
										<Button type="submit" size="sm" variant="dark">
											{state.submitting ? (
												<>
													<Spinner animation="border" size="sm" /> Submitting
												</>
											) : (
												state.button_text
											)}
										</Button>
									</div>
								)}
							</Form>
						</div>
					)}
					{state.error && <div className="error">{state.error}</div>}
				</div>
			</Card.Body>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	eventRegistration,
	submitEventRegistration,
};

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(CMSRegistrationForm));
