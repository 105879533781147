import React from 'react';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import { FirstSixSession, SecondFourSessions } from 'assets/data/static';
import VideoSessions from '../Event/VideoSessions';

const SessionForm = ({
	handleSelectAll,
	changeVideoSession,
	default_select,
	sessionFields,
	selectedSessions,
	defaultFirstTime,
	registrationsActive,
}) => {
	return (
		<>
			<div className="multiple-session mb-5 mt-5">
				<h3 className="select-session">
					Select which session(s) you would like to register for
				</h3>
				<Form.Group controlId="formBasicCheckbox">
					<Form.Check
						type="checkbox"
						label="Subscribe all Sessions"
						checked={default_select}
						value={default_select}
						onChange={(e) => handleSelectAll(e)}
						disabled={!registrationsActive}
					/>
				</Form.Group>
			</div>
			{sessionFields.map((session, index) => (
				<>
					<Row className="mt-2 mb-5" key={index}>
						<Col>
							<Accordion className="personal-card">
								<Card>
									<Accordion.Toggle
										as={Card.Header}
										className="cursor vs-flex header-block"
										eventKey="0"
									>
										<div className="block">
											{session.name}
											<div>
												<label className="label-container">
													<input
														name={session.id}
														onChange={(e) => changeVideoSession(e)}
														disabled={!registrationsActive}
														checked={
															defaultFirstTime
																? default_select
																: selectedSessions &&
																  selectedSessions.includes(session.id)
														}
														className="float-left checkbox"
														type="checkbox"
													/>
													<span className="checkmark"></span>
													<span className="label">Select this session</span>
												</label>
											</div>
										</div>
										<div>
											<FontAwesomeIcon
												icon={faChevronDown}
												className="pull-right"
											/>
										</div>
									</Accordion.Toggle>
									<VideoSessions
										videoSessions={
											index === 0 ? FirstSixSession : SecondFourSessions
										}
									/>
								</Card>
							</Accordion>
						</Col>
					</Row>
				</>
			))}
		</>
	);
};

export default SessionForm;
