export const getSystem = (state) => state.system;

export const togglePosition = (state) => {
	const system = getSystem(state);
	return system.sidebar;
};

export const sidebarToggler = (state) => {
	const system = getSystem(state);
	return system.displaySidebarToggle;
};

export const videoToggler = (state) => {
	const system = getSystem(state);
	return system.displayVideoToggle;
};

export const getGoogleTagId = (state) => {
	const system = getSystem(state);
	return system.googleId;
};

export const getColors = (state) => {
	const system = getSystem(state);
	let colors = {
		headerBg: system.header_bg,
		footerBg: system.footer_bg,
		bodyDynamic: system.body_dynamic,
	};
	return colors;
};

export const isPlayerDisplay = (state) => {
	const system = getSystem(state);
	return system.displayVideoPlayer;
};
