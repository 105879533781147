import React from 'react';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideAccordion from './SideAccordion';
import AllQuestions from './AllQuestions';
import Question from './Forms/Question';
import MyQuestions from './MyQuestions';

const Activities = ({
	displayAll,
	displayMy,
	displayAsk,
	question_description_caption,
}) => {
	return (
		<Tabs id="uncontrolled-tab-example">
			{displayAll && (
				<Tab eventKey="recent" title="Recent">
					<AllQuestions />
				</Tab>
			)}

			{displayAsk && (
				<Tab eventKey="ask" title="Ask Question">
					<Question
						question_description_caption={question_description_caption}
					/>
				</Tab>
			)}

			{displayMy && (
				<Tab eventKey="my-questions" title="My Questions">
					<MyQuestions />
				</Tab>
			)}
		</Tabs>
	);
};

const LiveQA = (props) => {
	const {
		label,
		displayAll,
		displayMy,
		displayAsk,
		question_description_caption,
	} = props;
	return (
		<div className="my-4 personal-nav">
			<SideAccordion
				icon={<FontAwesomeIcon icon={faCommentAlt} />}
				title={label}
				component={
					<Activities
						displayAsk={displayAsk}
						displayMy={displayMy}
						displayAll={displayAll}
						question_description_caption={question_description_caption}
					/>
				}
				open={true}
			/>
		</div>
	);
};

export default LiveQA;
