import React from 'react';
import { getImageUrl } from 'helper/helper';
import { SpeakersData } from 'assets/data/static';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Speaker = () => {
	return (
		<div className="speakers mt-4">
			{SpeakersData.length > 0 ? (
				SpeakersData.map((data, index) => (
					<div className="speaker" key={index}>
						<>
							<div className="speaker-details">
								<Row>
									<Col md={3} sm={12} lg={2}>
										<div className="speakers-container">
											<img
												onError={(e) => (e.target.style.display = 'none')}
												src={getImageUrl(`/assets/images/${data.photo}`)}
											/>
										</div>
									</Col>
									<Col md={9} sm={12} lg={10} className="pl-4">
										<div className="speaker-info">{data.name}</div>
										<div
											dangerouslySetInnerHTML={{
												__html: data.description,
											}}
										></div>
									</Col>
								</Row>
							</div>
						</>
						<div className="speaker-divider"></div>
					</div>
				))
			) : (
				<>No Data Found</>
			)}
		</div>
	);
};

export default Speaker;
