import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, Link, NavLink } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isLoggedIn } from 'selectors/auth';
import Button from 'react-bootstrap/Button';
import { eventInformation } from 'actions/event';
import PATH from 'routes';
import { Helmet } from 'react-helmet';
import { endDateSplit, SUBSCRIBE_BY_SESSION, sortEvents } from 'util/helper';
import { getContentBlock } from 'selectors/event';
import { validDateTime } from 'util/helper';
import { getImageUrl } from 'helper/helper';

const Information = (props) => {
	const initialState = {
		name: '',
		collections: [],
		loading: true,
		error: '',
		redirect_to_video_session: false,
		display_event_filter: true,
		session_subscribe: 3,
	};
	let { slug } = useParams();
	const [state, setState] = useState(initialState);
	let isLoggedIn = props.isLoggedIn(slug);

	if (!isLoggedIn) {
		props.history.push('/');
	}
	const { contentBlocks } = props;

	const callbackSuccess = (data) => {
		let {
			redirect_to_video_session,
			slug,
			video_session,
			display_event_filter,
			session_subscribe,
		} = data;
		if (redirect_to_video_session) {
			if (video_session) {
				props.history.push(`/${slug}/watch-now/${video_session}`);
			} else {
				props.history.push(PATH.HOME);
			}
		} else if (!display_event_filter) {
			props.history.push(PATH.HOME);
		} else {
			setState({
				...data,
				loading: false,
				session_subscribe: session_subscribe,
			});
		}
	};

	useEffect(() => {
		if (state.collections) {
			state.collections.forEach((data) => {
				let sessions = data.sessions;
				if (sessions) {
					sessions.forEach((session) => {
						let video_sessions = session.video_session;
						let sortedData = video_sessions.sort(sortEvents);
						session.video_session = sortedData;
					});
				}
			});
			setState({ ...state, collections: state.collections });
		}
	}, [state.collections]);

	const callbackError = () => {
		setState({
			...state,
			loading: false,
			error:
				"We are sorry to say that we don't support this browser. Please try another browser. Sorry for your inconvenience.",
		});
	};

	useEffect(() => {
		if (isLoggedIn) {
			const { eventInformation } = props;
			eventInformation(slug, callbackSuccess, callbackError);
		}
	}, [slug, isLoggedIn]);

	return (
		<div className="event-info all-vs mt-4">
			{state.name && (
				<Helmet>
					<title>
						{contentBlocks.tab_heading ? contentBlocks.tab_heading : state.name}
					</title>
				</Helmet>
			)}
			{state.loading ? (
				<p>Loading sessions...</p>
			) : (
				<div>
					<Row>
						<Col className="my-2">
							<div className="title">
								{contentBlocks.info_heading
									? contentBlocks.info_heading
									: state.name}
							</div>
						</Col>
					</Row>
					{contentBlocks.above_my_session && (
						<Row>
							<Col>
								<div
									dangerouslySetInnerHTML={{
										__html: contentBlocks.above_my_session,
									}}
								></div>
							</Col>
						</Row>
					)}

					<Row>
						{state.collections &&
							state.collections.map((collection, index) => (
								<Col key={index} className="my-2" sm={12} md={12}>
									{collection.sessions.length > 0 &&
										collection.sessions.map((session, sessionIndex) => (
											<div key={sessionIndex}>
												<Row>
													<Col md={6} sm={12}>
														<div className="sub-title collection-title">
															{session.title}
														</div>
													</Col>
													<Col md={6} sm={12}>
														{state.session_subscribe ===
															SUBSCRIBE_BY_SESSION && (
															<Button
																as={NavLink}
																to={`/event/${slug}/sessions/${session.id}`}
																className="float-right btn-theme"
																size="sm"
															>
																Edit my sessions
															</Button>
														)}
													</Col>
												</Row>
												{session.video_session.length > 0 &&
													session.video_session.map((video, videoIndex) => (
														<Card key={videoIndex} className="my-4">
															<Card.Body>
																<Row>
																	<Col md={10}>
																		<div className="title">{video.name}</div>
																		<div className="mt-2">
																			<p className="text">
																				<strong>
																					{video.live_start_display} -{' '}
																					{endDateSplit(video.live_end)}
																				</strong>
																			</p>
																			<p
																				dangerouslySetInnerHTML={{
																					__html: video.session_information,
																				}}
																				className="justify text"
																			></p>
																		</div>
																	</Col>
																	<Col md={2} className="my-2">
																		{video.id && (
																			<Link
																				to={`/${slug}/watch-now/${video.id}`}
																			>
																				<Button
																					className={`pull-right ${
																						validDateTime(
																							video.live_start,
																							video.live_end
																						)
																							? 'is-live'
																							: ''
																					}`}
																					size="lg"
																				>
																					{validDateTime(
																						video.live_start,
																						video.live_end
																					)
																						? 'Live'
																						: 'View'}
																				</Button>
																			</Link>
																		)}
																	</Col>
																</Row>
															</Card.Body>
														</Card>
													))}
											</div>
										))}
								</Col>
							))}
					</Row>
					{contentBlocks.below_my_session && (
						<Row>
							<Col>
								<div
									dangerouslySetInnerHTML={{
										__html: contentBlocks.below_my_session,
									}}
								></div>
							</Col>
						</Row>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	eventInformation,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Information));
