import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { getImageUrl } from 'helper/helper';

const VideoSessions = ({ videoSessions }) => {
	return (
		<Accordion.Collapse eventKey="0">
			<Card.Body className="all-vs">
				<div className="grid-container">
					{videoSessions.map((vs, index) => (
						<Card key={index}>
							<Card.Body className="mt-5 ml-2">
								<Row>
									<Col md={12}>
										<h6>
											<img src={getImageUrl('/assets/images/icon-clock.png')} />{' '}
											{vs.timing}
										</h6>
										<h1
											dangerouslySetInnerHTML={{
												__html: vs.sessionName,
											}}
											className="title"
										></h1>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="my-2 ">
										<div
											dangerouslySetInnerHTML={{
												__html: vs.sessionInformation,
											}}
										></div>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
				</div>
			</Card.Body>
		</Accordion.Collapse>
	);
};

export default VideoSessions;
