import { takeLatest, all, call, select, put } from 'redux-saga/effects';
import {
	currentVideo,
	assetVideo,
	videoTracking,
	submitQuestion,
	getAllQuestions,
	getSessionPoll,
	submitPoll,
	voteQuestion,
	getUserSessions,
	updateUserSessions,
} from 'api/session';
import { firstEvent } from 'api/event';
import {
	CURRENT_VIDEO,
	ASSET_VIDEO,
	VIDEO_TRACKING_LOGS,
	SUBMIT_QUESTION,
	ALL_QUESTIONS,
	GET_SESSION_POLL,
	SUBMIT_POLL,
	FIRST_EVENT,
	VOTE_QUESTION,
	GET_USER_SESSIONS,
	UPDATE_USER_SESSIONS,
} from 'actions/types';
import { getUserInfo, getUserFullName } from 'selectors/account';

import {
	updateCurrentVideo,
	updateAssetVideo,
	myQuestions,
	updateQuestionState,
} from 'actions/session';
import { updateSystem } from 'actions/system';

function* handleCurrentVideo(action) {
	const { video_session_id, slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(currentVideo, video_session_id, slug);
		if (!res) throw new Error('connection error');
		if (!res.data) {
			callbackError(res);
		} else {
			const {
				show_side_bar_toggle,
				show_video_toggle,
				header_bg,
				footer_bg,
				body_dynamic,
			} = res.data;
			yield put(
				updateSystem(
					show_side_bar_toggle,
					show_video_toggle,
					header_bg,
					footer_bg,
					body_dynamic
				)
			);
			yield put(updateCurrentVideo(res.data));
			callbackSuccess(res.data);
		}
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchCurrentVideo() {
	yield takeLatest(CURRENT_VIDEO, handleCurrentVideo);
}

function* handleAssetVideo(action) {
	const { id, slug, assetCallBackSuccess, assetCallBackError } = action;
	try {
		const res = yield call(assetVideo, id, slug);
		if (!res) throw new Error('connection error');
		if (!res.data) {
			assetCallBackError(res);
		} else {
			yield put(updateAssetVideo(res.data.data));
			assetCallBackSuccess(res.data);
		}
	} catch (e) {
		if (assetCallBackError) assetCallBackError(e.message);
	}
}

function* watchAssetVideo() {
	yield takeLatest(ASSET_VIDEO, handleAssetVideo);
}

function* handleVideoTracking(action) {
	const { eventId, videoId, video_timestamp, uniqueSessionId } = action;
	try {
		const { tenantId, userId } = yield select(getUserInfo);
		yield call(
			videoTracking,
			video_timestamp,
			tenantId,
			userId,
			videoId,
			eventId,
			uniqueSessionId,
			{}
		);
		return '';
	} catch (e) {
		return '';
	}
}

function* watchVideoTracking() {
	yield takeLatest(VIDEO_TRACKING_LOGS, handleVideoTracking);
}

function* handleSubmitQuestion(action) {
	const {
		videoId,
		question,
		is_anonymous,
		slug,
		questionCallBackSuccess,
		questionCallBackError,
		user,
	} = action;
	try {
		const res = yield call(
			submitQuestion,
			videoId,
			question,
			is_anonymous,
			slug,
			user
		);
		if (!res) throw new Error('connection error');
		if (!res.status) {
			questionCallBackError(res);
		} else {
			questionCallBackSuccess();
			const user_name = yield select(getUserFullName);
			yield put(
				updateQuestionState(videoId, {
					question: question,
					isAnonymous: is_anonymous,
					user: user_name,
					myQuestion: true,
				})
			);
		}
	} catch (e) {
		console.log(e, 'error');
		if (questionCallBackError) questionCallBackError(e.message);
	}
}

function* watchSubmitQuestion() {
	yield takeLatest(SUBMIT_QUESTION, handleSubmitQuestion);
}

function* handleAllQuestions(action) {
	const { videoId, slug, callbackSuccess, callbackError } = action;
	try {
		const { userId } = yield select(getUserInfo);
		const res = yield call(getAllQuestions, videoId, slug, userId);
		if (!res) throw new Error('connection error');
		if (res.status) {
			callbackSuccess(res.data);
			yield put(myQuestions(res.data, videoId));
		} else {
			callbackError();
		}
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchAllQuestions() {
	yield takeLatest(ALL_QUESTIONS, handleAllQuestions);
}

function* handleSessionPoll(action) {
	const { videoId, slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(getSessionPoll, videoId, slug);
		if (!res) throw new Error('connection error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			callbackError();
		}
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchSessionPoll() {
	yield takeLatest(GET_SESSION_POLL, handleSessionPoll);
}

function* handleSubmitPoll(action) {
	const { pollId, choices, slug, callbackSuccess, callbackError } = action;
	try {
		const { userId } = yield select(getUserInfo);
		if (!userId) return;
		const res = yield call(submitPoll, pollId, choices, userId, slug);
		if (!res) throw new Error('connection error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			callbackError();
		}
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchSubmitPoll() {
	yield takeLatest(SUBMIT_POLL, handleSubmitPoll);
}

function* handleFirstEvent(action) {
	const { callbackSuccess } = action;
	try {
		const res = yield call(firstEvent);
		if (res.status) {
			callbackSuccess(res.data);
		}
	} catch (e) {
		console.log('Error to retrieve first event for login slug in navigation');
	}
}

function* watchFirstEvent() {
	yield takeLatest(FIRST_EVENT, handleFirstEvent);
}

function* handleVoteQuestion(action) {
	const { rowId, voteState, slug, id, voteSuccess, voteError } = action;
	try {
		const res = yield call(voteQuestion, rowId, voteState, slug, id);
		if (res.status) {
			voteSuccess(res.data);
		}
	} catch (e) {
		voteError(e);
	}
}

function* watchVoteQuestion() {
	yield takeLatest(VOTE_QUESTION, handleVoteQuestion);
}

function* handleGetUserSession(action) {
	const { slug, session_id, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(getUserSessions, slug, session_id);
		if (!res) throw new Error('connection error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			callbackError();
		}
	} catch (e) {
		if (callbackError) callbackError(e.message);
	}
}

function* watchHandleGetSession() {
	yield takeLatest(GET_USER_SESSIONS, handleGetUserSession);
}

function* handleUpdateUserSession(action) {
	const { slug, session_block_id, session_id, submitSuccess, submitError } =
		action;
	try {
		const res = yield call(
			updateUserSessions,
			slug,
			session_block_id,
			session_id
		);
		if (!res) throw new Error('connection error');
		if (res.status) {
			submitSuccess(res.data);
		} else {
			submitError(res);
		}
	} catch (e) {
		submitError(e);
	}
}

function* watchHandleSessionUpdate() {
	yield takeLatest(UPDATE_USER_SESSIONS, handleUpdateUserSession);
}

export default function* sessionSaga() {
	yield all([
		watchCurrentVideo(),
		watchAssetVideo(),
		watchVideoTracking(),
		watchSubmitQuestion(),
		watchAllQuestions(),
		watchSessionPoll(),
		watchSubmitPoll(),
		watchFirstEvent(),
		watchVoteQuestion(),
		watchHandleGetSession(),
		watchHandleSessionUpdate(),
	]);
}
