import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getGoogleTagId } from 'selectors/system';
import ReactGA from 'react-ga';

const GoogleTag = (props) => {
	const { getGoogleTagId } = props;

	useEffect(() => {
		ReactGA.initialize(getGoogleTagId);
	}, [getGoogleTagId]);

	useEffect(() => {
		ReactGA.set({ page: window.location.pathname });
		ReactGA.pageview(window.location.pathname + window.location.search);
	});
	return <></>;
};

const mapStateToProps = (state) => {
	return {
		getGoogleTagId: getGoogleTagId(state),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(GoogleTag));
