import JwtDecode from 'jwt-decode';

import { getState, dispatchFromStore } from 'services/ReduxService';

import { getAccessToken, getRefreshToken } from 'selectors/auth';
import { refreshInfoUpdate, authLogoutControl } from 'actions/auth';
import { refreshAccessToken } from 'api/auth';
import { getUserEmail } from 'selectors/account';

export const accessToken = async (slug) => {
	let state = getState();
	const accessToken = getAccessToken(state, slug);
	const refreshToken = getRefreshToken(state, slug);

	if (!accessToken || !refreshToken) {
		dispatchFromStore(authLogoutControl(slug));
		return 'logout';
	}

	// JwtDecode() returns
	/*
    email: "test@gmail.com"
    exp: "2020-06-23 23:17:09.794487"
	token_type: "access"
	jti
  */
	// We need expires here.
	const decoded = JwtDecode(accessToken);
	const refreshDecode = JwtDecode(refreshToken);

	if (refreshDecode.exp < Date.now() / 1000) {
		dispatchFromStore(authLogoutControl(slug));
	} else {
		// until expired time in minutes
		// if expiry time of current accessToken<1 and
		// refreshToken is still valid then refetch accessToken and update store
		localStorage.setItem('exp', new Date(refreshDecode.exp * 1000));
		if (decoded.exp < Date.now() / 1000) {
			const token = getRefreshToken(state, slug);
			const email = getUserEmail(state);
			const res = await refreshAccessToken(token, email, slug);
			if (res.errorCode === 500 || res.errorCode === 400) {
				dispatchFromStore(authLogoutControl(slug));
			}
			const { access, refresh } = await res.data;
			dispatchFromStore(refreshInfoUpdate(access, refresh, slug));
		}
	}

	state = getState();
	return getAccessToken(state, slug);
};
