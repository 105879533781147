import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { eventRegistration, submitEventRegistration } from 'actions/event';
import Form from 'react-bootstrap/Form';
import Fields from './Fields';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { isLoggedIn } from 'selectors/auth';
import { MULTIPLE_SESSION, EMAIL, CONFIRM_EMAIL } from 'constants/Fields';
import { validateFormWithoutSession } from 'util/Validator';
import { ErrorToast, SuccessToast } from 'components/ToastMessage/Toast';
import SessionForm from './SessionForm';
import { SUBSCRIBE_BY_SESSION } from 'util/helper';

const Registration = (props) => {
	const initial = {
		loading: true,
		formWithoutSession: [],
		sessionFields: [],
		error: '',
		submitForm: {},
		reload: false,
		emailStack: [],
		default_select: true,
		submitting: false,
		selectedSessions: [],
		defaultFirstTime: true,
	};
	const [state, setState] = useState(initial);
	let { slug, event_slug } = useParams();

	if (props.isLoggedIn(event_slug)) {
		props.history.push(`/${event_slug}/info`);
	}

	const handleChange = (e) => {
		let name = e.target.name;
		let split_name = name.split('-');

		// If > 1, then multiple session field
		if (split_name.length > 1) {
			if (state.submitForm[split_name[2]]) {
				let hasSessions = state.submitForm[split_name[2]].indexOf(
					split_name[1]
				);
				if (hasSessions > -1) {
					// Deselect
					state.submitForm[split_name[2]].splice(hasSessions, 1);
				} else {
					// Select
					state.submitForm[split_name[2]].push(split_name[1]);
				}
			} else {
				state.submitForm[split_name[2]] = [split_name[1]];
			}
		} else {
			state.submitForm[`${e.target.name}`] = e.target.value;
		}
		setState({
			...state,
			submitForm: { ...state.submitForm },
		});
	};

	const callbackSuccess = (data) => {
		setState({ ...state, ...data, loading: false });
	};
	const callbackError = () => {
		setState({ ...state, error: 'Event does not exists.', loading: false });
	};

	useEffect(() => {
		const { eventRegistration } = props;
		eventRegistration(slug, callbackSuccess, callbackError);
	}, [slug, state.reload === true]);

	useEffect(() => {
		if (state.form_fields) {
			var formWithoutSession = state.form_fields.filter(function (data) {
				return data.field_type != MULTIPLE_SESSION;
			});

			var sessionFields = [];
			let selectedSessions = [];

			if (SUBSCRIBE_BY_SESSION === state.session_subscribe) {
				sessionFields = state.all_sessions.sort(sortEvents);
				sessionFields.forEach(function (session) {
					selectedSessions.push(session.id);
				});
			}

			var emailStack = formWithoutSession.filter(function (data) {
				return data.field_type === EMAIL || data.field_type === CONFIRM_EMAIL;
			});

			setState({
				...state,
				formWithoutSession: formWithoutSession,
				sessionFields: sessionFields,
				emailStack: emailStack,
				displaySessions: state.session_subscribe === SUBSCRIBE_BY_SESSION,
				selectedSessions: selectedSessions,
			});
		}
	}, [state.form_fields]);

	const submitSuccess = (responseData) => {
		const { login, message } = responseData;
		const { slug } = responseData.data;
		if (login) {
			props.history.push(`/${slug}/info`);
		} else {
			setState(initial);
			setState({ ...state, reload: true });
		}
		SuccessToast(message, 3000);
		setState({ ...state, submitting: false });
	};
	const submitError = (e) => {
		ErrorToast(e.message[0].error);
		setState({ ...state, submitting: false });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setState({ ...state, submitting: true });
		if (state.emailStack.length > 1) {
			if (
				state.submitForm[state.emailStack[0].id] !==
				state.submitForm[state.emailStack[1].id]
			) {
				ErrorToast('Two email does not match');
				setState({ ...state, submitting: false });
				return;
			}
		}
		let error = validateFormWithoutSession(
			state.formWithoutSession,
			state.submitForm
		);
		if (error.length <= 0) {
			const { submitEventRegistration } = props;
			submitEventRegistration(
				slug,
				state.submitForm,
				state.selectedSessions,
				submitSuccess,
				submitError
			);
		}
	};
	const handleSelectAll = (e) => {
		if (e.target.checked) {
			let selectedSession = [];
			state.all_sessions.forEach(function (session) {
				selectedSession.push(session.id);
			});
			setState({
				...state,
				selectedSessions: selectedSession,
				default_select: !state.default_select,
				defaultFirstTime: false,
			});
		} else {
			setState({
				...state,
				selectedSessions: [],
				default_select: !state.default_select,
				defaultFirstTime: false,
			});
		}
	};

	const changeVideoSession = (e) => {
		let hasSessions = state.selectedSessions.indexOf(parseInt(e.target.name));
		if (hasSessions > -1) {
			// Deselect
			state.selectedSessions.splice(hasSessions, 1);
		} else {
			// Select
			state.selectedSessions.push(parseInt(e.target.name));
		}

		setState({
			...state,
			selectedSessions: [...state.selectedSessions],
			defaultFirstTime: false,
			default_select:
				state.selectedSessions.length === state.all_sessions.length,
		});
	};

	const sortEvents = (a, b) => {
		const bandA = a.id;
		const bandB = b.id;

		let comparison = 0;
		if (bandA < bandB) {
			comparison = -1;
		} else if (bandA > bandB) {
			comparison = 1;
		}
		return comparison;
	};

	return (
		<div className="event-details">
			{state.loading ? (
				<h4>Loading....</h4>
			) : (
				state.formWithoutSession && (
					<div className="content">
						<div
							className="event-description break-word"
							dangerouslySetInnerHTML={{
								__html: state.event_description,
							}}
						></div>
						<div className="form-title theme-color">{state.title}</div>
						<p>
							Please complete the form below and your details should be sent to
							you shortly.
						</p>
						<Form onSubmit={(e) => handleSubmit(e)}>
							<div className="form-flex mt-5">
								{state.formWithoutSession.map((data, index) => (
									<div
										key={index}
										className={data.field_type === 4 && 'full-form'}
									>
										<Fields
											data={data}
											submitForm={state.submitForm}
											change={handleChange}
											registrationsActive={state.registrations_active}
										/>
									</div>
								))}
							</div>

							{state.displaySessions ? (
								<>
									{state.sessionFields.length > 0 && (
										<SessionForm
											handleSelectAll={handleSelectAll}
											changeVideoSession={changeVideoSession}
											default_select={state.default_select}
											sessionFields={state.sessionFields}
											selectedSessions={state.selectedSessions}
											defaultFirstTime={state.defaultFirstTime}
											registrationsActive={state.registrations_active}
										/>
									)}
								</>
							) : (
								''
							)}
							<div>
								We will try to answer all questions on the day, if a question is
								not answered during the event, the question will be taken on
								notice and a response provided after the AMM by being published
								in the AMM Minutes. The AMM Minutes will be available on LUCRF
								Super’s website within one month of the AMM.
							</div>
							{(state.sessionFields.length > 0 || state.formWithoutSession) && (
								<div className="mx-auto mt-3">
									<Button type="submit" size="sm" variant="dark mx-auto">
										{state.submitting ? (
											<>
												<Spinner animation="border" size="sm" /> Submitting
											</>
										) : (
											state.button_text
										)}
									</Button>
								</div>
							)}
						</Form>
						<p className="mt-5 text-center">
							If you are having troubles accessing the stream, please contact{' '}
							<a
								href="mailto:Support@CMSAustralasia.com"
								target="_blank"
								rel="noreferrer"
							>
								Support@CMSAustralasia.com
							</a>
						</p>
					</div>
				)
			)}
			{state.error && <div className="error">{state.error}</div>}
			<div className="row mt-5">
				<div className="col-md-12">
					<div className="privacy-policies">
						<a
							href="https://www.cmsaustralasia.com/privacy-policy/"
							target="_blank"
							className="CMS-policy"
							rel="noreferrer"
						>
							CMS Privacy Policy
						</a>
						<a
							href="https://www.lucrf.com.au/privacy-policy"
							target="_blank"
							className="LUCRF-policy"
							rel="noreferrer"
						>
							LUCRF Privacy Policy
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	eventRegistration,
	submitEventRegistration,
};

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(Registration));
