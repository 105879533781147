export const getImageUrl = (uri) => {
	return process.env.PUBLIC_URL + uri;
};

export const reloadPage = () => {
	window.location.reload();
};

export const generateRandomHash = (HASHLENGTH) => {
	const characters = '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
	let string = '';
	for (let i = 0; i <= HASHLENGTH; i++) {
		string += characters[Math.floor(Math.random() * characters.length)];
	}
	return string;
};

export const THEO_ERROR_CODES = [
	7004, 7012, 7011, 4001, 4000, 4002, 5003, 5002, 5001, 5000, 6000, 6001, 3000,
	3001,
];
// not required = 10001, 10000, 7005, 7006, 7003, 7002, 7000, 7009, 7010, 7007, 7008,7001, 11000, 2000, 2003, 2001, 2002, 5004, 8001, 8000, 8002, 9000, 9001
